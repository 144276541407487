* {
    font-family: "Alata", sans-serif;
    font-weight: 400;
    font-style: normal;
}

body {
    background-color: #edeae1;
}

.form-container {
    background-color: white;
    width: 300px;
    margin: 0 auto;
    height: 430px;
    margin-top: 4rem;
    text-align: center;
    color: #696969;
    justify-content: center;
}

.form-container .title-form {
    font-size: 28px;
    padding: 6px;
}

.form-container .spaces-form {
    display: grid;
    margin: 16px;
    width: 250px;
    margin: 0 auto;
    margin-top: 1.3rem;
}

.spaces-form input {
    background-color: #e3e3e3;
    border: none;
    height: 30px;
    border-radius: 9px;
}

.spaces-form label {
    margin-bottom: 6px;
    display: flex;
}

.button-login {
    background-color: #69696960;
    border: none;
    height: 29px;
    width: 130px;
    border-radius: 9px;
    color: #3b3b3b;
}

.button-container {
    text-align: center;
    margin-top: 2.3rem;
}

.button-container button {
    cursor: pointer;
}

.button-container button:hover {
    background-color: #69696948;
}

.form-container .alert-login {
    color: red;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
}

.spaces-form {
    position: relative;
}

.spaces-form .fa-eye {
    position: absolute;
    right: 10px;
    top: 60%;
    cursor: pointer;
}

.spaces-form .fa-eye:hover {
    color: #69696990;
    transition: 0.4s;
}


@media (min-width: 900px) {

    .form-container {
        width: 400px;
    }

    .logo-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

}