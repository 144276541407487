* {
    font-family: "Alata", sans-serif;
    font-weight: 400;
    font-style: normal;
}
body {
    background-color: #edeae1;
}
.password-container{
    background-color: white;
    width: 310px;
    margin: 0 auto;
    height: 500px;
    margin-top: 9rem;
    text-align: center;
    color: #696969;
    margin-bottom: 7rem;
}

.password-container .title-form-password {
    font-size: 19px;
    padding: 32px;
}
.password-container .form-container-password .form-group-password label {
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    margin-bottom: 16px;
    display: flex;
}
.form-container-password input {
    background-color: #e3e3e3;
    border: none;
    height: 30px;
    border-radius: 9px;
    width: 250px;
}
.btn-primary {
    background-color: #f1f1f1;
    border: none;
    height: 29px;
    width: 130px;
    border-radius: 9px;
    color: #696969;
}
.btn-primary {
    text-align: center;
    margin-top: 1.7rem;
}
.btn-primary {
    cursor: pointer;
}
button:hover {
    background-color: #69696948;
}
.form-container-password .alert-danger{
    color: rgb(203, 44, 44);
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1.3rem;
}

.form-group-password label{
    margin-top: 2rem;
}

.form-group-password label{
    margin-top: 2rem;
}

.form-group-password {
    position: relative;
}

.form-group-password #eye-password {
    position: absolute;
    right: 35px;
    top: 65%;
    cursor: pointer;
}

@media (min-width: 900px) {

    .password-container {
        width: 400px;
        margin-top: 5rem;
    }

    .btn-primary {
        margin-top: 3rem;
    }

    .form-group-password #eye-password {
        right: 85px;
    }

    .form-group-password #eye-password:hover {
        color: #69696990;
        transition: 0.4s;
    }
}