.form-container .form-newproduct {
    color: #201f1f;
    background-color: white;
    height: 480px;
    margin: 0 auto;
    width: 327px;
    margin-bottom: 6rem;
}

.form-container .form-newproduct .form-img input {
    margin-left: -3.4rem;
}

.form-container .form-newproduct .form-img {
    margin-bottom: 0.4rem;
}

.form-container .form-newproduct .form-spaces {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.form-spaces label {
    padding: 10px;
    display: block;
}

.form-img,
.form-spaces {
    margin: 60px;
    text-align: center;
    justify-content: center;
}

.form-img,
.form-spaces,
label {
    font-weight: 400;
}

.form-container .form-newproduct .form-spaces {
    padding: 20px;
}

.form-newproduct input {
    background-color: #ccc;
    border: none;
    height: 30px;
    border-radius: 15px;
    margin-top: 1rem;
}

.form-container .form-newproduct .form-img label {
    margin-top: 2rem;
}

.products-container h2 {
    font-size: 35px;
    color: #201f1fb9;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.products-container {
    text-align: center;
    width: 100%;
}

.logout-button {
    margin-top: 0.6rem;
    margin-left: 0.5rem;
    margin-bottom: 2%;
}

.logout-button button {
    background-color: #201f1f87;
    color: white;
    border: none;
    width: 72px;
    height: 24px;
    cursor: pointer;
    font-size: 10px;
    border-radius: 5px;
}

.logout-button button:hover {
    background-color: #8a878794;
    transition: 0.5s;
}

.products-container .table-container {
    font-size: 11px;
    margin-top: 2rem;
    justify-content: center;
    width: 100%;
    display: flex;
}

.table-container td {
    font-size: 10px;
}

.tbody-tables {
    padding: 10px;
}

.table-container table thead tr th {
    font-size: 10px;
    margin-bottom: 3rem;
    font-weight: 600;
    color: #201f1f;
}

.delete-container i {
    cursor: pointer;
    padding: 4px;
}

.tbody-tables td input {
    width: 40px;
}

.tbody-tables td img {
    width: 4rem;
    height:95px;
    object-fit: cover;
}

.tbody-tables #description {
    font-size: 8px;
}

.link-product a {
    color: white;
    font-size: 13px;
    text-decoration: none;
}

.link-product {
    width: 150px;
    height: 30px;
    background-color: #8a8787;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 5px;
    padding: 0px 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-width: 80%;
    max-height: 80%;
}

.modal img {
    width: 100%;
    height: 320px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-overlay .modal-content-delete {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 80%;
}

.modal-content-delete p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #201f1f;
}

.confirm-button,
.cancel-button {
    background-color: #8a8787;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    width: 60px;
}

.cancel-button {
    background-color: #8a8787;
}

.confirm-button:hover,
.cancel-button:hover {
    opacity: 0.8;
    transition: 0.5s;
    color: black;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.link-product {
    margin-bottom: 20px;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}

tbody tr:hover {
    background-color: #f1f1f1;
    transition: 0.5s;
}

.delete-container i {
    cursor: pointer;
    margin: 0 5px;
}

.modal-delete {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-delete {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
}

.modal-content-delete img {
    max-width: 100%;
    height: auto;
}

@media (min-width:600px) {
    .form-container .form-newproduct {
        width: 400px;
    }

    .form-container .form-newproduct .form-img input {
        margin-right: -3rem;
    }

    .products-container .table-container {
        width: 96%;
        margin: 0 auto;
        margin-top: 4rem;
    }

    .modal img {
        height: 480px;
    }

    .tbody-tables td input {
        width: 60px;
    }
}

@media (min-width:900px) {
    .form-container .form-newproduct {
        width: 700px;
    }

    .form-container .form-newproduct .form-img input {
        margin-right: 1rem;
    }

    .form-container .form-newproduct .form-img label {
        margin-right: 4rem;
    }

    .form-container .form-newproduct .form-img {
        margin-left: 8rem;
    }

    .products-container h2 {
        font-size: 45px;
    }

    .link-product:hover {
        background-color: #8a878794;
        transition: 0.5s;
    }

    .modal img {
        height: 770px;
    }

    .delete-container i {
        font-size: 0.7rem;
    }

    .delete-container i:hover {
        color: #8a8787;
        transition: 0.5s;
    }

    #description {
        width: 60%;
    }

    .tbody-tables td input {
        width: 80px;
    }
}

@media (min-width:1100px) {
    .products-container .table-container {
        width: 95%;
        margin-top: 5rem;
    }

    .tbody-tables #description {
        font-size: 13px;
    }

    .table-container table thead tr th {
        font-size: 19px;
    }

    .tbody-tables td {
        font-size: 14px;
    }

    .logout-button button {
        width: 90px;
        height: 26px;
        font-size: 12px;
    }

    .logout-button button:hover {
        background-color: #8a878794;
        transition: 0.5s;
    }

    .tbody-tables td img {
        width: 9rem;
        height: 200px;
        object-fit: cover;
    }

    .modal img {
        height: 870px;
        margin-top: -1rem;
    }

    .delete-container i {
        font-size: 1.2rem;
    }

    .tbody-tables td {
        font-size: 17px;
    }

    .tbody-tables td input {
        width: 100px;
    }
}

@media (min-width:1300px) {
    .modal img {
        height: 530px;
        margin-top: -1rem;
    }

    .tbody-tables td input {
        width: 190px;
    }

}