.form-container-newproducts .form-newproduct {
    color: #201f1f;
    background-color: white;
    height: 590px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 10rem;
    align-items: center;
}

.form-container-newproducts {
    width: 330px;
    margin: 0 auto;
    margin-top: 2rem;
    height: 760px;
    text-align: center;
    margin-bottom: 6rem;
}

.form-container-newproducts .form-newproduct .form-spaces {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.form-spaces label {
    padding: 20px;
    display: block;
}
.form-img,
.form-spaces {
    margin: 60px;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
}
.form-img,
.form-spaces,
label {
    font-weight: 400;
}
.form-img input {
    width: 320px;
}

.form-container-newproducts .form-newproduct .form-img input {
    margin-top: 1rem;
}

.form-container-newproducts .form-newproduct .form-spaces {
    padding: 1px;
}

.form-newproduct input {
    background-color: #ccc;
    border: none;
    height: 30px;
    border-radius: 15px;
    margin-top: 1rem;
    text-align: center;
    margin: 0 auto;
}
.form-spaces textarea {
    background-color: #ccc;
    border: none;
    border-radius: 15px;
    width: 215px;
    height: 80px;
    margin-top: 1rem;
    text-align: center;
    margin: 0 auto;
}

.form-container-newproducts .form-newproduct .form-img label {
    margin-top: 2rem;
    padding: 10px;
}
.button-newproduct {
    background-color: #9a9a9a;
    border: none;
    height: 45px;
    width: 230px;
    border-radius: 9px;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
}
.title-newproduct {
    width: 100%;
    justify-content: center;
    display: flex;
    font-size: 50px;
}

.form-container-newproducts .title-newproduct h3 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 3rem;
    color: rgb(161, 159, 159);
    margin-top: -2rem;
    margin-bottom: 4rem;
}

.button-back {
    display: flex;
    justify-content: end;
    margin-bottom: 3rem;
    background-color: #201f1f43;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 15px;
    border-radius: 5px;
    height: 30px;
    padding: 3px 10px;
    margin-top: 0.4rem;
    margin-left: 0.3rem;
}


@media (min-width: 470px) {
    .form-container-newproducts {
        width: 600px;
    }

    .form-container-newproducts .form-newproduct {
        width: 500px;
        height: 700px;
    }

    .form-container-newproducts .title-newproduct h3 {
        font-size: 40px;
    }

    #title, #price {
        width: 360px;
    }
    .button-newproduct {
        color: white;
    }
    .button-newproduct:hover {
        background-color: #201f1f;
        transition: 0.3s;
        color: #494747;
    }
    .form-spaces textarea {
        width: 350px;
        height: 90px;
    }

    .form-container-newproducts {
        margin-bottom: 5rem;
    }

    .form-container-newproducts {
        padding: 20px;
    }

    .form-spaces {
        font-size: 18px;
    }

    .form-container-newproducts .form-img{
        margin: 0 auto;
        padding: 40px;
        font-size: 18px;
    }
}

@media (min-width:820px) {
    .button-back {
        margin-bottom: 3rem;
        font-size: 19px;
        height: 40px;
        padding: 5px 18px;
        margin-top: 0.8rem;
        margin-left: 0.8rem;
    }
    
    .button-back:hover {
        background-color: #8a8787;
    }
}

@media (min-width: 1330px) {

    .form-container-newproducts {
        margin-top: 3rem;
        margin-bottom: 10rem;
    }
}